import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles.css";
import { Link, useLocation, useParams } from "react-router-dom";
function Sidebar() {
  const location = useLocation();
  const [segments, setSegments] = useState([]);
  useEffect(()=>{
    const pathSegments = window.location.href.split("/");
    setSegments(pathSegments);
    console.log(pathSegments)

  },[location])
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      
    <Link to="/" className="brand-link">
       <img src="" alt="logo" class="brand-image img-circle elevation-3" style={{opacity: .8}}></img>
      <span className="brand-text font-weight-light">TMS</span>
    </Link>

    <div className="sidebar">
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img src="" class="img-circle elevation-2" alt="image"></img>
        </div>
        <div className="info">
          <a href="#" className="d-block">Admin</a>
        </div>
      </div>

      <div className="form-inline">
      <div className="input-group" data-widget="sidebar-search">
        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search"/>
        <div className="input-group-append">
          <button className="btn btn-sidebar">
            <i className="fas fa-search fa-fw"></i>
          </button>
        </div>
      </div>
    

    <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-item">
                <Link to="/" className={`nav-link ${segments.length>0 && segments[3] == "" ?'active':'' } `}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>
                    Dashboard
                </p>
                </Link>
            </li>

            <li className={`nav-item ${segments.length>0 && segments[3]=="categories" ?'menu-is-opening  menu-open':'' } `}>
                <Link to="/categories"  className={`nav-link ${segments.length>0 && segments[3]=="categories" ?'active':'' }`}>
                    
                <i className="nav-icon fas fa-columns"></i>
                <p>
                    Bus companies
                    <i className="right fas fa-angle-left"></i>
                </p>
                </Link>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                        <Link to="/categories" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>List bus companies</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/categories/add" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add bus company</p>
                        </Link>
                    </li>
                </ul>
            </li>

            <li className={`nav-item ${segments.length>0 && segments[3]=="products" ?'menu-is-opening  menu-open':'' } `}>
                <Link to="/products" className={`nav-link ${segments.length>0 && segments[3]=="products" ?'active':'' }`}>
                    
                <i className="nav-icon fas fa-bus"></i>
                <p>
                    Buses
                    <i className="right fas fa-angle-left"></i>
                </p>
                </Link>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                        <Link to="/products" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>List buses</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/products/add" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Create bus</p>
                        </Link>
                    </li>
                </ul>
            </li>

            <li className={`nav-item ${segments.length>0 && segments[3]=="customers" ?'menu-is-opening  menu-open':'' } `}>
                <Link to="/customers"  className={`nav-link ${segments.length>0 && segments[3]=="customers" ?'active':'' }`}>
                    
                <i className="nav-icon fas fa-users"></i>
                <p>
                    Customers
                    <i className="right fas fa-angle-left"></i>
                </p>
                </Link>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                        <Link to="/customers" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>List customers</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/customers/add" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Customer</p>
                        </Link>
                    </li>
                </ul>
            </li>

            <li className="nav-item">
                <Link to="/orders" className={`nav-link ${segments.length>0 && segments[3]=="orders" ?'active':'' } `}>
                <i className="nav-icon fas fa-book"></i>
                <p>
                    Bookings
                </p>
                </Link>
            </li>

            {/* <li className={`nav-item ${segments.length>0 && segments[3]=="shops" ?'menu-is-opening  menu-open':'' } `}>
                <Link to="/shops"  className={`nav-link ${segments.length>0 && segments[3]=="shops" ?'active':'' }`}>
                    
                <i className="nav-icon fas fa-th"></i>
                <p>
                    List
                    <i className="right fas fa-angle-left"></i>
                </p>
                </Link>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                        <Link to="/shops" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Shops</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/shops/add" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add shop</p>
                        </Link>
                    </li>
                </ul>
            </li> */}

            {/* <li className="nav-item">
                <Link to="/on-boarding"  className={`nav-link ${segments.length>0 && segments[3]=="on-boarding" ?'active':'' } `}>
                <i className="nav-icon fas fa-plug"></i>
                <p>
                    Onboarding
                </p>
                </Link>
            </li> */}

          <li className={`nav-item ${segments.length>0 && segments[3]=="locations" ?'menu-is-opening  menu-open':'' } `}>
                <Link to="/locations"  className={`nav-link ${segments.length>0 && segments[3]=="locations" ?'active':'' }`}>
                    
                <i className="nav-icon fas fa-map"></i>
                <p>
                    locations
                    <i className="right fas fa-angle-left"></i>
                </p>
                </Link>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                        <Link to="/locations" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>List locations</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/locations/add" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add location</p>
                        </Link>
                    </li>
                </ul>
            </li>

          <li 
          className={`nav-item ${segments.length>0 && segments[3]=="users" ?'menu-is-opening  menu-open':'' } `}>
                <Link to="/users"  className={`nav-link ${segments.length>0 && segments[3]=="users" ?'active':'' }`}>
                    
                <i className="nav-icon fas fa-th"></i>
                <p>
                    User management
                    <i className="right fas fa-angle-left"></i>
                </p>
                </Link>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                        <Link to="/users" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Users</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/users/add" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add user</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/users/roles" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Roles</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/users/roles/add" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Role</p>
                        </Link>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
    </div>

    



      {/*<>
    <div className=" sidebar d-flex justify-content-between flex-column bg-dark text-white py-3 ps-3 pe-5 vh-100">
      <div>
        <a href="" className="p-3 text-decoration-none text-white">
          <i className="bi bi-person-circle fs-4 me-4"></i>
          <span className="fs-4">Admin</span>
        </a>
        <hr className="text-white mt-2" />
        <ul className="nav nav-pils flex-column mt-2">
          <li
            className={active === 1 ? "active nav-item p-2" : "nav-item p-2"}
            onClick={(e) => setActive(1)}
          >
            <Link to="/" className="p-1 text-decoration-none text-white">
              <i className="bi bi-speedometer2 me-3 fs-5"></i>
              <span className="fs-4">Dashboard</span>
            </Link>
          </li>
          <li
            className={active === 2 ? "active nav-item p-2" : "nav-item p-2"}
            onClick={(e) => setActive(2)}
          >
            <Link to="/customers" className="p-1 text-decoration-none text-white">
              <i className="bi bi-people me-3 fs-4"></i>
              <span className="fs-4">Customers</span>
            </Link>
          </li>
          <li
            className={active === 3 ? "active nav-item p-2" : "nav-item p-2"}
            onClick={(e) => setActive(3)}
          >
            <Link to="/orders" className="p-1 text-decoration-none text-white">
              <i className="bi bi-table me-3 fs-4"></i>
              <span className="fs-4">orders</span>
            </Link>
          </li>
          <li
            className={active === 4 ? "active nav-item p-2" : "nav-item p-2"}
            onClick={(e) => setActive(4)}
          >
            <Link to="/addProducts" className="p-1 text-decoration-none text-white">
              <i className="bi bi-basket me-3 fs-4"></i>
              <span className="fs-4">Add Products</span>
            </Link>
          </li>
          <li
            className={active === 5 ? "active nav-item p-2" : "nav-item p-2"}
            onClick={(e) => setActive(5)}
          >
            <Link to="/orderStatus" className="p-1 text-decoration-none text-white">
              <i className="bi bi-grid me-3 fs-4"></i>
              <span className="fs-4">order Status</span>
            </Link>
          </li>
          <li
            className={active === 6 ? "active nav-item p-2" : "nav-item p-2"}
            onClick={(e) => setActive(6)}
          >
            <Link to="/seller" className="p-1 text-decoration-none text-white">
              <i className="bi bi-shop me-3 fs-4"></i>
              <span className="fs-4">Become A seller</span>
            </Link>
          </li>
        </ul>
      </div>
      <div>
        <hr className="text-white mt-2" />

        <ul className="nav nav-pils flex-column mt-2">
          <li
            className={active === 7 ? "active nav-item p-2" : "nav-item p-2"}
            onClick={(e) => setActive(7)}
          >
            <Link to="/shops" className="p-1 text-decoration-none text-white">
              <i className="bi bi-shop me-3 fs-4"></i>
              <span className="fs-4">Shops</span>
            </Link>
          </li>
          <li
            className={active === 8 ? "active nav-item p-2" : "nav-item p-2"}
            onClick={(e) => setActive(8)}
          >
            <Link to="/onboarding" className="p-1 text-decoration-none text-white">
              <i className="bi bi-cash me-3 fs-4"></i>
              <span className="fs-4">OnBoarding</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
    </>*/}  
    </div>  
    </aside>
    
  );
}

export default Sidebar;

import { Link } from "react-router-dom";
import Product from "../Product";
import { useEffect, useState } from "react";
import { DataURLS } from "../../../utils";
import { Loading } from "../../../components";
const Products = ()=>{
    const [buses,setBuses] = useState({
        error:false,
        loading:false,
        message:"",
        results:[]
    });

    const fetchBuses = ()=>{
        setBuses({
                ...buses,
                error:false,
                loading:true,
                message:"",
            })
        fetch(DataURLS.buses,{
            method:"GET"
        }).then(req=>req.json())
        .then(res=>{
            console.log(res);
            setBuses({
                error:false,
                loading:false,
                message:"",
                results:res
            })
        }).catch(e=>{
            console.log(e);
            setBuses({
                ...buses,
                error:true,
                loading:false,
                message:"An error occurred, try again later.",
            })
        })
    }

    const handleDelete = (id)=>{

    }

    useEffect(()=>{
        fetchBuses();
    },[]);
    return(
    <>
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Buses</h1>
          </div>
        </div>
      </div>
    </div>

    <Product/>

    <section className="content">
        <div className="container-fluid">
            <Link to="/products/add">
                <button className="btn btn-success btn-md" style={{marginBottom:10}} >Add bus</button>
            </Link>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Manage your buses.</h3>

                            <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: 150,}}>
                                <input type="text" name="table_search" className="form-control float-right" placeholder="Search"/>

                                <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>name</th>
                                    <th>price(ksh.)</th>
                                    <th>Company</th>
                                    <th>isAvailable</th>
                                    <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        buses.error?
                                        <tr>
                                            <td>{buses.message}</td>
                                        </tr>:
                                        buses.loading?
                                        <tr>
                                            <td colSpan={7} >
                                                <Loading.ProgressLoading/>
                                            </td>
                                        </tr>:
                                        buses.results.length<1?
                                        <tr>
                                            <td>No buses found.</td>
                                        </tr>:
                                        buses.results.map((bus,index)=>{
                                            return(
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td><img src="" alt="bus image" /></td>
                                                    <td>{bus.name}</td>
                                                    <td>{bus.fare}</td>
                                                    <td>{bus.name}</td>
                                                    <td><input type="checkbox" checked={bus.isAvailable} readOnly /></td>
                                                    <td>
                                                        <a to="#">
                                                        <button className="btn btn-primary btn-sm" style={{
                                                            marginLeft:10
                                                        }} 
                                                        data-toggle="modal" data-target="#modal-view-product"
                                                        >View</button>
                                                        </a>
                                                        <Link to={`/products/edit/${bus.slug}`}>
                                                        <button className="btn btn-secondary btn-sm" style={{
                                                            marginLeft:10
                                                        }} >Edit</button>
                                                        </Link>
                                                        <a href="#">
                                                        <button 
                                                        onClick={()=>handleDelete(bus._id)}
                                                        className="btn btn-danger btn-sm" style={{
                                                            marginLeft:10
                                                        }} >Delete</button>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>
    )
}

export default Products;
import React from 'react'
import { Link } from 'react-router-dom';

function Customers() {
  return (
    <>
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Customers</h1>
          </div>
        </div>
      </div>
    </div>
    
    <Customer/>

    <section className="content">
        <div className="container-fluid">
            <Link to="/customers/add">
                <button className="btn btn-success btn-md" 
                style={{marginBottom:10}} >Add customer</button>
            </Link>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Manage your customers.</h3>

                            <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: 150,}}>
                                <input type="text" name="table_search" className="form-control float-right" placeholder="Search"/>

                                <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>name</th>
                                    <th>email</th>
                                    <th>phone number</th>
                                    <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><img src="" alt="customer image" /></td>
                                        <td>Customer name</td>
                                        <td>customer@gmail.com </td>
                                        <td>011111200</td>
    
                                        <td>
                                            <a to="#">
                                            <button className="btn btn-primary btn-sm" style={{
                                                marginLeft:10
                                            }} 
                                            data-toggle="modal" data-target="#modal-view"
                                            >View</button>
                                            </a>
                                            <Link to={`/customers/edit/${1}`}>
                                            <button className="btn btn-secondary btn-sm" style={{
                                                marginLeft:10
                                            }} >Edit</button>
                                            </Link>
                                            <a href="#">
                                            <button className="btn btn-danger btn-sm" style={{
                                                marginLeft:10
                                            }} >Delete</button>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>
  )
}

const Customer = ({customer})=>{
    return(
        <div class="modal fade" id="modal-view">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Customer name</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <tbody>
                                    <tr>
                                        <td><img src="" alt="shop image" /></td>
                                    </tr>
                                    <tr>
                                        <td>name</td>
                                        <td>Customer name</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>customer email</td>
                                    </tr>
                                    <tr>
                                        <td>Phone number</td>
                                        <td>07123445</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#">
                                            <button className="btn btn-danger btn-sm" style={{
                                                marginLeft:10
                                            }} >Delete</button>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal-header">
                        <h4 class="modal-title">Customer's addresses</h4>
                    </div>
                    <div class="modal-body">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>County</th>
                                    <th>City</th>
                                    <th>Constituency</th>
                                    <th>Location</th>
                                    <th>Area/landmark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>County</td>
                                        <td>City</td>
                                        <td>Constituency</td>
                                        <td>Location </td>
                                        <td>Area</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Customers;
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataURLS } from '../../../utils';
import { UserContext } from '../../../contexts';
import { Loading } from '../../../components';

function Orders() {
  const ordersData = [
    { id: 1, product: 'User 1', quantity: 2, price: 999 },
    { id: 2, product: 'User 2', quantity: 1, price: 1999 },
    { id: 3, product: 'User 3', quantity: 3, price: 199 },
    { id: 4, product: 'User 4', quantity: 2, price: 799 },
    { id: 5, product: 'User 5', quantity: 1, price: 399 },
    { id: 6, product: 'User 6', quantity: 1, price: 149 },
    { id: 7, product: 'User 7', quantity: 1, price: 349 },
    { id: 8, product: 'User 8', quantity: 2, price: 299 },
    { id: 9, product: 'User 9', quantity: 1, price: 1299 },
    { id: 10, product: 'User 10', quantity: 2, price: 79 },
  ];
const [userData,setUserData] = useContext(UserContext);
    const [bookings,setBookings] = useState({
        error:false,
        loading:false,
        message:"",
        results:[]
    });

    const fetchBookings = ()=>{
        const token = userData.data.token;
        setBookings({
                ...bookings,
                error:false,
                loading:true,
                message:"",
            })
        fetch(`${DataURLS.bookings}/all`,{
            method:"GET",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                "Authorization":`Bearer ${token}`
            },
        }).then(req=>req.json())
        .then(res=>{
            console.log(res);
            setBookings({
                error:res?.error?true:false,
                loading:false,
                message:res?.error,
                results:res?.error?[]:res
            })
        }).catch(e=>{
            console.log(e);
            setBookings({
                ...bookings,
                error:true,
                loading:false,
                message:"An error occurred, try again later.",
            })
        })
    }

    const handleDelete = (id)=>{

    }

    useEffect(()=>{
        fetchBookings();
    },[]);

  
  return (
    <>
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">bookings</h1>
          </div>
        </div>
      </div>
    </div>

    <Order/>

    <section className="content">
        <div className="container-fluid">

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Manage your bookings.</h3>

                            <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: 150,}}>
                                <input type="text" name="table_search" className="form-control float-right" placeholder="Search"/>

                                <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>name</th>
                                    <th>Amount(ksh.)</th>
                                    <th>Company</th>
                                    <th>Status</th>
                                    <th>isPaid</th>
                                    <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {
                                        bookings.error?
                                        <tr>
                                            <td>{bookings.message}</td>
                                        </tr>:
                                        bookings.loading?
                                        <tr>
                                            <td colSpan={5} >
                                                <Loading.ProgressLoading/>
                                            </td>
                                        </tr>:
                                        bookings.results.length<1?
                                        <tr>
                                            <td>No bookings found.</td>
                                        </tr>:
                                    bookings.results.map((item,index)=>{
                                      return(
                                        <tr
                                        key={index}
                                        >
                                          <td>{index+1}</td>
                                          <td><img src="" alt="user image" /></td>
                                          <td>{item?.product}</td>
                                          <td>{item?.price}</td>
                                          <td>Company 1</td>
                                          <td>pending</td>
                                          <td><input type="checkbox" checked readOnly /></td>
                                          <td>
                                              <a to="#">
                                            <button className="btn btn-primary btn-sm" style={{
                                                marginLeft:10
                                            }} 
                                            data-toggle="modal" data-target="#modal-view"
                                            >View</button>
                                            </a>
                                              <a href="#">
                                              <button className="btn btn-secondary btn-sm" style={{
                                                  marginLeft:10
                                              }} >Mark as paid</button>
                                              </a>
                                              <a href="#">
                                              <button className="btn btn-danger btn-sm" style={{
                                                  marginLeft:10
                                              }} >Cancel</button>
                                              </a>
                                            </td>
                                        </tr>
                                      )
                                    })
                                  }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
)
}

const Order = ({order})=>{
    return(
        <div class="modal fade" id="modal-view">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Order id</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <tbody>
                                    <tr>
                                        <td><img src="" alt="customer image" /></td>
                                    </tr>
                                    <tr>
                                        <td>Customer name</td>
                                        <td>User name</td>
                                    </tr>
                                    <tr>
                                        <td>Company</td>
                                        <td>Company name</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>pending</td>
                                    </tr>
                                    <tr>
                                        <td>Payment status</td>
                                        <td> paid</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>Ksh. 200</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal-header">
                        <h4 class="modal-title">Seats</h4>
                    </div>

                  <div class="modal-body">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">

                              <thead>
                                <th>#</th>
                                <th>Image</th>
                                <th>Seat number</th>
                                <th>price</th>
                                <th>Total</th>
                              </thead>

                                <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td><img src="" alt="seat image" /></td>
                                        <td>name</td>
                                        <td>100</td>
                                        <td>200</td>
                                    </tr>
                                    
                                    <tr>
                                      <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Ksh. 200</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* <div class="modal-header">
                        <h4 class="modal-title">Address</h4>
                    </div>

                  <div class="modal-body">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                  <thead>
                                    <th>County</th>
                                    <th>City</th>
                                    <th>Constituency</th>
                                    <th>Location</th>
                                    <th>Area/landmark</th>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>Customer County</td>
                                    <td>Customer City</td>
                                    <td>Customer Constituency</td>
                                    <td>Customer Location</td>
                                    <td>Customer Area/landmark</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                <div class="modal-footer">
                    <button type="submit" name="add-role" class="btn btn-primary">Mark as Paid</button>
                    <button type="submit" name="add-role" class="btn btn-secondary">Pay</button>
                    <button type="submit" name="add-role" class="btn btn-danger">Cancel</button>
                </div>
                </div>

                
            </div>
        </div>
    )
}

export default Orders
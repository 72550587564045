import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { DataURLS } from '../../utils';

function EditUser() {
  const params = useParams();
    const [user,setUser] = useState({
        error:false,
        loading:false,
        message:"",
        results:[]
  });

  const [eUseur,setEuser] = useState({
    name:"",
    email:"",
    phone:"",
    address:""
  });
      const fetchUser = ()=>{
        setUser({
                ...user,
                error:false,
                loading:true,
                message:"",
            })
        fetch(`${DataURLS.users}/${params.id}`,{
            method:"GET"
        }).then(req=>req.json())
        .then(res=>{
            console.log(res);
            setUser({
                error:false,
                loading:false,
                message:"",
                results:res
            });
            setEuser(res);
        }).catch(e=>{
            console.log(e);
            setUser({
                ...user,
                error:true,
                loading:false,
                message:"An error occurred, try again later.",
            })
        })
    }

    useEffect(()=>{
      fetchUser();
    },[]);
  return (
    <>
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Edit User</h1>
          </div>
        </div>
      </div>
    </div>

    <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">User name</h3>
            </div>

            <div className="card-body">
              <div className="form-group">
                <label >Name</label>
                <input 
                onChange={(e)=>setEuser({...eUseur,name:e.target.value})}
                value={eUseur?.name}
                required type="text" className="form-control" name="productName" placeholder="Name..."/>
              </div>
              <div className="form-group">
                <label >Email</label>
                <input 
                onChange={(e)=>setEuser({...eUseur,email:e.target.value})}
                value={eUseur?.email}
                required type="text" className="form-control" name="productName" placeholder="Name..."/>
              </div>
              <div className="form-group">
                <label >Phone number</label>
                <input 
                onChange={(e)=>setEuser({...eUseur,phone:e.target.value})}
                value={eUseur?.phone}
                required type="text" className="form-control" name="productName" placeholder="Name..."/>
              </div>
              <div className="form-group">
                <label >Address</label>
                <input 
                onChange={(e)=>setEuser({...eUseur,address:e.target.value})}
                value={eUseur?.address}
                required type="text" className="form-control" name="productName" placeholder="Name..."/>
              </div>


              <div className="form-group">
                  <label htmlFor="exampleInputFile">Image</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input required type="file" className="custom-file-input" name="productImage" id="exampleInputFile"/>
                      <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                </div>
              <div className="card-footer">
                <button name="add" className="btn btn-primary">Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default EditUser;
import React from 'react'

function OrderStatus() {
  const orderStatusData = [
    { id: 1, orderNumber: "ORD001", status: "onDelivery" },
    { id: 2, orderNumber: "ORD002", status: "onDelivery" },
    { id: 3, orderNumber: "ORD003", status: "onDelivery" },
    { id: 4, orderNumber: "ORD004", status: "onDelivery" },
    { id: 5, orderNumber: "ORD005", status: "onDelivery" },
    { id: 6, orderNumber: "ORD006", status: "delivered" },
    { id: 7, orderNumber: "ORD007", status: "delivered" },
    { id: 8, orderNumber: "ORD008", status: "delivered" },
    { id: 9, orderNumber: "ORD009", status: "delivered" },
    { id: 10, orderNumber: "ORD010", status: "delivered" },
  ];
  return (
    <table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Order Number</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {orderStatusData.map((order) => (
          <tr key={order.id}>
            <td>{order.id}</td>
            <td>{order.orderNumber}</td>
            <td>{order.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default OrderStatus
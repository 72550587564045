import React,{useContext, useEffect, useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DataURLS } from '../../utils';
import { UserContext } from '../../contexts';
import { Loading } from '../../components';

function SignIn({setUser}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData,setUserData] = useContext(UserContext);

  const [credentials,setCredentials] = useState({
    email:"",
    password:"",
  });

  const [data,setData] = useState({
    state:false,
    loading:false,
    message:"",
  });

  

  const handleLogin = ()=>{
    if(credentials.email == ""){
      return false;
    }
    if(credentials.password == ""){
      return false;
    }

    setData({
      state:false,
      loading:true,
      message:"",
    });


    let formData = new FormData();
    formData.append("email",credentials.email);
    formData.append("password",credentials.password);
    let data = {
      email:credentials.email,
      password:credentials.password
    }

    fetch(DataURLS.ownerSignIn,{
      method:"POST",
      body:JSON.stringify(data),
      // credentials:true,
      headers:{
        "Accept":"application/json",
        "Content-Type":"application/json"
      }
    }).then(req=>req.json())
    .then(response=>{
      console.log(response);
      
      if(!response.user){
        setData({
          state:true,
          loading:false,
          message:response.error,
        });
        return;
      }
      let goToLocation = location.state?.from?.pathname || "/";
      setUserData({loggedIn:true,data:response});
      localStorage.setItem("tms_admin",JSON.stringify({loggedIn:true,data:response}));
      setData({
        state:false,
        loading:false,
        message:"Successful login.",
      });

      setTimeout(()=>{
        navigate(goToLocation);
      },2000)
      
    }).catch(e=>{
      console.log(e);
      setData({
        state:true,
        loading:false,
        message:"An error occurred. Try again later.",
      });
    });

    
  }

  useEffect(()=>{
    const userInfo = localStorage.getItem("tms_admin");
      if(userInfo !== null){
        navigate("/");
      }
  },[]);
  return (

<div className="login-box" >
  <div className="card card-outline card-primary" style={{borderTopColor: "var(--app-color)"}}>
    <div className="card-header text-center">
      <a href="#" className="h1"><b style={{color:"var(--app-color)"}} >TMS</b></a>
    </div>
    <div className="card-body">
      <p className="login-box-msg">Sign in to start your session</p>
      {
                data.message || data.loading?
                <div
                  style={{
                    height:"fit-content",
                    width:"100%"
                  }}
                >
                  {
                    data.loading?
                    <Loading.ProgressLoading height={100} />:
                    data.state?
                    <p className='text-danger' >{data.message}</p>:
                    <p className='text-success' >{data.message}</p>
                  }
                  
                </div>:<></>
              }
        <div className="input-group mb-3">
          <input 
            type="email" 
            name="email" 
            className="form-control" 
            placeholder="Email"
            onChange={(e)=>setCredentials({...credentials,email:e.target.value})}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="password" 
          name="password" className="form-control" onChange={(e)=>setCredentials({...credentials,password:e.target.value})} 
          placeholder="Password"/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="icheck-primary">
              <input type="checkbox" id="remember"/>
              <label for="remember">
                Remember Me
              </label>
            </div>
          </div>
          <div className="col-4">
            <button 
                onClick={handleLogin}
              className="btn btn-primary btn-block"
              style={{backgroundColor:"var(--app-color)",
                    border:"none"}}>Sign In</button>
          </div>
        </div>
      

      <p className="mb-1">
        <Link to="/forgot-password">Forgot password?</Link>
      </p>

      <p className="mb-1">
        <Link to="/sign-up">Sign up?</Link>
      </p>

    </div>
  </div>
</div>
  )
}

export default SignIn;
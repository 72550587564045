import React, { useContext, useEffect } from 'react'

import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { DataURLS } from '../../../utils';
import { UserContext } from '../../../contexts';

let enumValues = ["AC", "Delux", "Normal", "Suspense AC", "Suspense Delux"];

function AddProducts() {
  const [userData,setUserData] = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [credentials,setCredentials] = useState({
    name:"",
    slug:"",
    type:enumValues[0],
    busNumber:"",
    fare:0.00,
    pickupPoints:[],
    droppingPoints:[],
    numberOfSeats:0,
    description:"",
    journeyDate:null,
    startLocation:"",
    endLocation:""
  });

    const [locations,setLocations] = useState({
        error:false,
        loading:false,
        message:"",
        results:[]
    });

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/products");
    // handle form submission logic here

  };

   const create = ()=>{
        const token = userData.data.token;
        console.log(token);
        fetch(DataURLS.buses,{
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                "Authorization":`Bearer ${token}`
            },
            body:JSON.stringify(credentials),
            method:"POST",
        }).then(req=>req.json()).then(response=>{
            console.log(response);
            if(response?.error){
                //navigate("/sign-in",{state:{from:location},replace:true});
            }
            
        }).catch(e=>{
            console.log(e);
        })
    }



    const fetchLocations = ()=>{
        setLocations({
                ...locations,
                error:false,
                loading:true,
                message:"",
            })
        fetch(DataURLS.locations,{
            method:"GET"
        }).then(req=>req.json())
        .then(res=>{
            console.log(res);
            setLocations({
                error:false,
                loading:false,
                message:"",
                results:res
            })
        }).catch(e=>{
            console.log(e);
            setLocations({
                ...locations,
                error:true,
                loading:false,
                message:"An error occurred, try again later.",
            })
        })
    }

    useEffect(()=>{
        fetchLocations();
    },[]);
  return (
    <>
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0">Add bus</h1>
        </div>
      </div>
    </div>
  </div>
<section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">bus</h3>
            </div>

            <div className="card-body">
              <div className="form-group">
                <label >Name</label>
                <input onChange={(e)=>setCredentials({...credentials,name:e.target.value})} required type="text" className="form-control" name="name" placeholder="Name..."/>
              </div>
              <div className="form-group">
                <label >slug</label>
                <input onChange={(e)=>setCredentials({...credentials,slug:e.target.value})} required type="text" className="form-control" name="slug" placeholder="slug..."/>
              </div>
              <div className="form-group">
                <label >type</label>
                <select
                
                onChange={(e)=>{
                  console.log(e);
                  setCredentials({...credentials,type:e.target.value})}} >
                  {
                    enumValues.map((val,index)=>{
                      return <option key={index} >{val}</option>
                    })
                  }
                </select>
                {/* <input 
                  onChange={(e)=>setCredentials({...credentials,type:e.target.value})} 
                  required 
                  type="text" 
                  className="form-control" name="type" placeholder="type..."/> */}
              </div>

              <div className="form-group">
                <label >Start location</label>
                <select
                
                onChange={(e)=>{
                  
                    let value = locations.results.filter(item=>item.name==e.target.value);
                    setCredentials({...credentials,startLocation:value[0]._id});
                    
                    }} >
                  {
                    locations.results.map((val,index)=>{
                      return <option key={index} >{val.name}</option>
                    })
                  }
                </select>
                {/* <input 
                  onChange={(e)=>setCredentials({...credentials,type:e.target.value})} 
                  required 
                  type="text" 
                  className="form-control" name="type" placeholder="type..."/> */}
              </div>

              <div className="form-group">
                <label >end location</label>
                <select
                
                onChange={(e)=>{
                  
                    let value = locations.results.filter(item=>item.name==e.target.value);
                    setCredentials({...credentials,endLocation:value[0]._id});
                    
                    }}  >
                  {
                    locations.results.map((val,index)=>{
                      return <option key={index} >{val.name}</option>
                    })
                  }
                </select>
                {/* <input 
                  onChange={(e)=>setCredentials({...credentials,type:e.target.value})} 
                  required 
                  type="text" 
                  className="form-control" name="type" placeholder="type..."/> */}
              </div>

              <div className="form-group">
                <label >Bus number</label>
                <input onChange={(e)=>setCredentials({...credentials,busNumber:e.target.value})} required type="text" className="form-control" name="busnumber" placeholder="bus number..."/>
              </div>

              <div className="form-group">
                <label >fare</label>
                <input onChange={(e)=>setCredentials({...credentials,fare:e.target.value})} required type="text" className="form-control" name="fare" placeholder="fare..."/>
              </div>

              <div className="form-group">
                <label >pickup points (separated by a spaces)</label>
                <input onChange={(e)=>setCredentials({...credentials,droppingPoints:e.target.value})} required type="text" className="form-control" name="pickupPoints" placeholder="pickup points..."/>
              </div>

              <div className="form-group">
                <label >Dropping points (separated by a spaces)</label>
                <input onChange={(e)=>setCredentials({...credentials,droppingPoints:e.target.value})} required type="text" className="form-control" name="droppingPoints" placeholder="dropping points..."/>
              </div>

              <div className="form-group">
                <label >number of seats</label>
                <input onChange={(e)=>setCredentials({...credentials,numberOfSeats:e.target.value})} required type="text" className="form-control" name="numberofseats" placeholder="number of seats..."/>
              </div>

              <div className="form-group">
                <label >description</label>
                <input onChange={(e)=>setCredentials({...credentials,description:e.target.value})} required type="text" className="form-control" name="description" placeholder="description..."/>
              </div>

              <div className="form-group">
                <label >date</label>
                <input onChange={(e)=>setCredentials({...credentials,journeyDate:e.target.value})} required type="date" className="form-control" name="date" placeholder="date..."/>
              </div>

              <div className="form-group">
                  <label htmlFor="exampleInputFile">Image</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input required type="file" accept='image/*' className="custom-file-input" name="productImage" id="exampleInputFile"/>
                      <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                </div>
              <div className="card-footer">
                <button onClick={create} className="btn btn-primary">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  );
}

export default AddProducts;
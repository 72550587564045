import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DataURLS } from '../../utils';
import { Loading } from '../../components';

function SignUp() {
const navigate = useNavigate();
  const location = useLocation();


  const [credentials,setCredentials] = useState({
    name:"",
    email:"",
    phone:"",
    address:"",
    citizenshipNumber:"",
    password:"",
    role:"superadmin"
  });

  const [data,setData] = useState({
    state:false,
    loading:false,
    message:"",
  });



  const handleRegister = ()=>{
    if(credentials.email == ""){
      return false;
    }
    if(credentials.password == ""){
      return false;
    }

    setData({
      state:false,
      loading:true,
      message:"",
    });

    let formData = new FormData();
    formData.append("email",credentials.email);
    formData.append("password",credentials.password);
    let data = {
      email:credentials.email,
      password:credentials.password
    }

    fetch(DataURLS.ownerSignUp,{
      method:"POST",
      body:JSON.stringify(credentials),
      // credentials:true,
      headers:{
        "Accept":"application/json",
        "Content-Type":"application/json"
      }
    }).then(req=>req.json())
    .then(response=>{
      console.log(response);
      //setUserData({loggedIn:true,data:{token:response.token,name:"User",email:"user@gmail.com"}});
      //localStorage.setItem("tms_admin",JSON.stringify({loggedIn:true,data:{token:response.token,name:"User",email:"user@gmail.com"}}));
      if(response.error){
        setData({
          state:true,
          loading:false,
          message:response.error,
        });
        return;
      }
      let goToLocation = location.state?.from?.pathname || "/sign-in";
      setData({
        state:false,
        loading:false,
        message:"Successful registration.",
      });
      setTimeout(()=>{
        navigate(goToLocation);
      },2000);
      
    }).catch(e=>{
      console.log(e);
      setData({
        state:true,
        loading:false,
        message:"An error occurred. Try again later.",
      });
    });

    
  }
  return (
    <div className="login-box" >
        <div className="card card-outline card-primary" style={{borderTopColor: "var(--app-color)"}}>
            <div className="card-header text-center">
            <a href="#" className="h1"><b style={{color:"var(--app-color)"}} >TMS</b></a>
            </div>
            <div className="card-body">
            <p className="login-box-msg">Sign up </p>
                {
                  data.message || data.loading?
                  <div
                    style={{
                      height:"fit-content",
                      width:"100%"
                    }}
                  >
                    {
                      data.loading?
                      <Loading.ProgressLoading height={100} />:
                      data.state?
                      <p className='text-danger' >{data.message}</p>:
                      <p className='text-success' >{data.message}</p>
                    }
                    
                  </div>:<></>
                }
                <div className="input-group mb-3">
                    <input
                    onChange={(e)=>setCredentials({...credentials,name:e.target.value})}
                    type="text" className="form-control" 
                    placeholder="name..." required name="firstName"/>
                    <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-user"></span>
                    </div>
                    </div>
                </div>

                

                <div className="input-group mb-3">
                    <input
                    onChange={(e)=>setCredentials({...credentials,email:e.target.value})}
                    type="email" name="email" 
                    required className="form-control" placeholder="Email"/>
                    <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                    </div>
                    </div>
                </div>

                <div className="input-group mb-3">
                    <input
                    onChange={(e)=>setCredentials({...credentials,phone:e.target.value})}
                    type="text" className="form-control" 
                    placeholder="phone number..." required />
                    <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-user"></span>
                    </div>
                    </div>
                </div>

                <div className="input-group mb-3">
                    <input
                    onChange={(e)=>setCredentials({...credentials,address:e.target.value})}
                    type="text" name="phoneNumber" 
                    required className="form-control" placeholder="address..."
                    
                    />
                    <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                    </div>
                    </div>
                </div>

                <div className="input-group mb-3">
                    <input
                    onChange={(e)=>setCredentials({...credentials,citizenshipNumber:e.target.value})}
                    type="text" className="form-control" 
                    placeholder="citizenship number..." required />
                    <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-user"></span>
                    </div>
                    </div>
                </div>

                <div className="input-group mb-3">
                    <input
                    onChange={(e)=>setCredentials({...credentials,password:e.target.value})}
                    type="password" 
                    name="password" required className="form-control" placeholder="Password"/>
                    <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-8">
                    <div className="icheck-primary">

                        <label for="agreeTerms">

                        </label>
                    </div>
                    </div>
                    <div className="col-4">
                    <button 
                    type="submit" 
                    name="register" 
                    className="btn btn-primary btn-block" 
                    onClick={handleRegister}
                    style={{backgroundColor:"var(--app-color)",
                            border:"none"}}>Sign up</button>
                    </div>
                    <p className="mb-1">
                        <Link to="/sign-in">Sign in</Link>
                    </p>
                </div>

            </div>
        </div>
    </div>
  )
}

export default SignUp;
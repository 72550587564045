import React from 'react'
import { Link } from 'react-router-dom';

function ForgotPassword() {
  return (
    <div className="login-box">
    <div className="card card-outline card-primary" style={{borderTopColor:"var(--app-color)"}}>
      <div className="card-header text-center">
        <a href="#" className="h1" style={{color:"var(--app-color)"}}><b>TMS</b></a>
      </div>
      <div className="card-body">
        <p className="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>

          <div className="input-group mb-3">
            <input type="email" name="email" className="form-control" placeholder="Email"/>
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button name="forgot" className="btn btn-primary btn-block"
                style={{backgroundColor:"var(--app-color)",border:"none"}}>Request new password</button>
            </div>
          </div>
        <p className="mt-3 mb-1">
          <Link to="/sign-in">Login</Link>
        </p>
      </div>
    </div>
  </div>
  )
}

export default ForgotPassword;
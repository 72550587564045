import React from 'react';

const Product = ({product})=>{
    return(
        <div class="modal fade" id="modal-view-product">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Bus name</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <tbody>
                                    <tr>
                                        <td><img src="" alt="product image" /></td>
                                    </tr>
                                    <tr>
                                        <td>name</td>
                                        <td>product name</td>
                                    </tr>
                                    <tr>
                                        <td>price</td>
                                        <td>product price</td>
                                    </tr>
                                    <tr>
                                        <td>shop</td>
                                        <td>shop name</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#">
                                            <button className="btn btn-danger btn-sm" style={{
                                                marginLeft:10
                                            }} >Delete</button>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product;
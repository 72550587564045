import React from 'react'
import { Link } from 'react-router-dom'

function Onboarding() {
  return (
    <>
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Onboarding</h1>
          </div>
        </div>
      </div>
    </div>

    <section className="content">
        <div className="container-fluid">

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Manage your shops.</h3>

                            <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: 150,}}>
                                <input type="text" name="table_search" className="form-control float-right" placeholder="Search"/>

                                <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>name</th>
                                    <th>email</th>
                                    <th>phone number</th>
                                    <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><img src="" alt="category image" /></td>
                                        <td>shop name</td>
                                        <td>shop email </td>
                                        <td>shop phone number </td>
                                        <td>
                                            <Link to="/categories">
                                            <button className="btn btn-primary btn-sm" style={{
                                                marginLeft:10
                                            }} >View</button>
                                            </Link>
                                            <Link to={`/categories/edit/${1}`}>
                                            <button className="btn btn-secondary btn-sm" style={{
                                                marginLeft:10
                                            }} >Edit</button>
                                            </Link>
                                            <a href="#">
                                            <button className="btn btn-danger btn-sm" style={{
                                                marginLeft:10
                                            }} >Delete</button>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>
  )
}

export default Onboarding
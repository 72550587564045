import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { DataURLS } from '../../utils';

const AddLocation = () => {
  const [userData,setUserData] = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [credentials,setCredentials] = useState({
    name:"",
    district:"",
  });

   const create = ()=>{
        const token = userData.data.token;
        console.log(token);
        fetch(DataURLS.locations,{
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                "Authorization":`Bearer ${token}`
            },
            body:JSON.stringify(credentials),
            method:"POST",
        }).then(req=>req.json()).then(response=>{
            console.log(response);
            if(response?.error){
                navigate("/sign-in",{state:{from:location},replace:true});
            }
            
        }).catch(e=>{
            console.log(e);
        })
    }
  return (
        <>
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0">Add location</h1>
        </div>
      </div>
    </div>
  </div>
<section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Location</h3>
            </div>

            <div className="card-body">
              <div className="form-group">
                <label >Name</label>
                <input onChange={(e)=>setCredentials({...credentials,name:e.target.value})} required type="text" className="form-control" name="productName" placeholder="Name..."/>
              </div>
              <div className="form-group">
                <label >District</label>
                <input onChange={(e)=>setCredentials({...credentials,district:e.target.value})} required type="text" className="form-control" name="productName" placeholder="Name..."/>
              </div>

              <div className="form-group">
                  <label htmlFor="exampleInputFile">Image(optional)</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input type="file" className="custom-file-input" name="productImage" id="exampleInputFile"/>
                      <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                </div>
              <div className="card-footer">
                <button onClick={create} className="btn btn-primary">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default AddLocation;
import React, { useEffect } from 'react'
import { useState } from "react";
import { DataURLS } from "../../../utils";
import { useParams } from 'react-router-dom';

function EditProduct() {
  const params = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");

  const [credentials,setCredentials] = useState({
    name:"",
    slug:"",
    type:null,
    busNumber:"",
    fare:0.00,
    boardingPoints:"",
    droppingPoints:"",
    numberOfSeats:0,
    description:"",
    journeyDate:null,
    startLocation:"",
    endLocation:""
  });
    const [bus,setBus] = useState({
        error:false,
        loading:false,
        message:"",
        results:[]
  });
      const fetchBus = ()=>{
        setBus({
                ...bus,
                error:false,
                loading:true,
                message:"",
            })
        fetch(`${DataURLS.buses}/${params.id}`,{
            method:"GET"
        }).then(req=>req.json())
        .then(res=>{
            console.log(res);
            setBus({
                error:false,
                loading:false,
                message:"",
                results:res
            });
            setCredentials(res);
        }).catch(e=>{
            console.log(e);
            setBus({
                ...bus,
                error:true,
                loading:false,
                message:"An error occurred, try again later.",
            })
        })
    }


  useEffect(()=>{
    fetchBus();
    
    window.scroll(0,0);
  },[])
  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission logic here
  };

  return (
     <>
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0">Edit bus</h1>
        </div>
      </div>
    </div>
  </div>
<section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">bus</h3>
            </div>

            <div className="card-body">
              <div className="form-group">
                <label >Name</label>
                <input
                onChange={(e)=>setCredentials({...credentials,name:e.target.value})}
                value={credentials?.name}
                required type="text" className="form-control" name="name" placeholder="Name..."/>
              </div>
              <div className="form-group">
                <label >slug</label>
                <input
                onChange={(e)=>setCredentials({...credentials,name:e.target.value})}
                value={credentials?.slug}
                required type="text" className="form-control" name="slug" placeholder="slug..."/>
              </div>
              <div className="form-group">
                <label >type</label>
                <input
                onChange={(e)=>setCredentials({...credentials,type:e.target.value})}
                value={credentials?.type}
                required type="text" className="form-control" name="type" placeholder="type..."/>
              </div>

              <div className="form-group">
                <label >Bus number</label>
                <input
                onChange={(e)=>setCredentials({...credentials,busNumber:e.target.value})}
                value={credentials?.busNumber}
                required type="text" className="form-control" name="busnumber" placeholder="bus number..."/>
              </div>

              <div className="form-group">
                <label >fare</label>
                <input
                onChange={(e)=>setCredentials({...credentials,fare:e.target.value})}
                value={credentials?.fare}
                required type="text" className="form-control" name="fare" placeholder="fare..."/>
              </div>

              <div className="form-group">
                <label >pickup points</label>
                <input
                onChange={(e)=>setCredentials({...credentials,boardingPoints:e.target.value})}
                value={credentials?.boardingPoints.toString()}
                required type="text" className="form-control" name="pickupPoints" placeholder="pickup points..."/>
              </div>

              <div className="form-group">
                <label >Dropping points</label>
                <input
                onChange={(e)=>setCredentials({...credentials,droppingPoints:e.target.value})}
                value={credentials?.droppingPoints.toString()}
                required type="text" className="form-control" name="droppingPoints" placeholder="dropping points..."/>
              </div>

              <div className="form-group">
                <label >number of seats</label>
                <input
                onChange={(e)=>setCredentials({...credentials,numberOfSeats:e.target.value})}
                value={credentials?.numberOfSeats}
                required type="text" className="form-control" name="numberofseats" placeholder="number of seats..."/>
              </div>

              <div className="form-group">
                <label >description</label>
                <input
                onChange={(e)=>setCredentials({...credentials,description:e.target.value})}
                value={credentials?.description}
                required type="text" className="form-control" name="description" placeholder="description..."/>
              </div>

              <div className="form-group">
                <label >date</label>
                <input
                onChange={(e)=>setCredentials({...credentials,journeyDate:e.target.value})}
                value={credentials?.journeyDate}
                required type="date" className="form-control" name="date" placeholder="date..."/>
              </div>

              <div className="form-group">
                  <label htmlFor="exampleInputFile">Image</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input required type="file" className="custom-file-input" name="productImage" id="exampleInputFile"/>
                      <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                </div>
              <div className="card-footer">
                <button onClick={handleSubmit} className="btn btn-primary">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  );
}

export default EditProduct
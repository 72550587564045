import React from 'react';

function EditShop() {
  return (
    <>
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0">Edit shop</h1>
        </div>
      </div>
    </div>
  </div>
<section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Shop name</h3>
            </div>

            <div className="card-body">
              <div className="form-group">
                <label >Name</label>
                <input required type="text" className="form-control" name="productName" placeholder="Name..."/>
              </div>
              <div className="form-group">
                <label >Location</label>
                <input required type="text" className="form-control" name="productName" placeholder="Name..."/>
              </div>
              <div className="form-check">
                  <input name="isAvailable" type="checkbox" className="form-check-input" id="exampleCheck1"/>
                  <label className="form-check-label" htmlFor="exampleCheck1">Status(tick to make it open)</label>
                </div>
              <div className="form-group">
                  <label htmlFor="exampleInputFile">Image (optional) </label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input required type="file" className="custom-file-input" name="productImage" id="exampleInputFile"/>
                      <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                </div>
              
            </div>
            <div className="card-footer">
              <button name="add" className="btn btn-primary">Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
  )
}

export default EditShop;
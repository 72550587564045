export const BASEURL = "https://tms-2k4s.onrender.com";

export const DataURLS = {
    userSignUp:`${BASEURL}/api/auth-user/signup`,
    userSignIn:`${BASEURL}/api/auth-user/signin`,
    userForgotPassword:`${BASEURL}/api/api-user/forgot-password`,
    userResetPassword:`${BASEURL}/api/api-user/reset-password`,
    buses:`${BASEURL}/api/bus`,
    allBusesAvailable:`${BASEURL}/api/all-bus-available`,
    search:`${BASEURL}/api/search`,
    filter:`${BASEURL}/api/filter`,
    locations:`${BASEURL}/api/locations`,
    travels:`${BASEURL}/api/travels`,

    users:`${BASEURL}/api/users`,
    bookings:`${BASEURL}/api/bookings`,

    ownerSignUp:`${BASEURL}/api/auth-owner/signup`,
    ownerSignIn:`${BASEURL}/api/auth-owner/signin`,
} 
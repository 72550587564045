import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import { Sidebar,Navbar } from "./Navigation";
import { Home } from "./pages/Home";
import {Products,AddProducts, EditProduct } from "./pages/Products";
import { AddShop, BecomeSeller, EditShop, Onboarding, Shops } from "./pages/Owner";
import { OrderStatus, Orders } from "./pages/Fulfilment";
import { AddCustomer, Customers, EditCustomer } from "./pages/Customers";
import { AddCategory, Categories, EditCategory } from "./pages/Categories";
import { ForgotPassword, SignIn, SignUp } from "./pages/Auth";
import { AddUser, EditUser, Users } from "./pages/Users";
import { AddRole, EditRole, Roles } from "./pages/Roles";
import { AddLocation, EditLocation, Locations } from "./pages/Locations";
import { UserContext } from "./contexts";


function App() {

  const [toggle, setToggle] = useState(false);
  const [user,setUser] = useState({
    loggedIn:false,
    data:{}
  });

  const [userData,setUserData] = useState({loggedIn:false,data:{}});
  const userDataProvider = useMemo(()=>[userData,setUserData],[userData,setUserData]);

  function Toggle(){
    setToggle(!toggle);
  }

  useEffect(()=>{

      /*const handleSize = () => {
        if(window.innerWidth > 768){
          setToggle(false);
        }
      }
      window.addEventListener('resize', handleSize);

        return () => {
            window.removeEventListener('resize', handleSize)
        }*/

    //check user existence
    const userInfo = localStorage.getItem("tms_admin");
    if(userInfo !== null){
      setUser({
        loggedIn:true,
        data:JSON.parse(userInfo)
      })

      console.log("Logged in",user);
      document.querySelector("body").classList.add("hold-transition");
      document.querySelector("body").classList.remove("login-page");
      document.querySelector("body").classList.add("sidebar-mini");
      document.querySelector("body").classList.add("layout-fixed");
      document.querySelector("#root").classList.add("wrapper");
    }else{
      document.querySelector("#root").classList.remove("wrapper");
      document.querySelector("body").classList.remove("sidebar-mini");
      document.querySelector("body").classList.remove("layout-fixed");
      document.querySelector("body").classList.add("hold-transition");
      document.querySelector("body").classList.add("login-page");
      setUser({
        loggedIn:false,
        data:{}
      })

      console.log("Not Logged in",user);
    }
      
  },[user.loggedIn])

  return (
    
    <BrowserRouter>
    <UserContext.Provider value={userDataProvider} >
      {
        user.loggedIn?
        <>
          <Navbar Toggle={Toggle} setUser={setUser} />
          <Sidebar/>
        </>
        :<></>
      }

      

        
        <div className={user.loggedIn?"content-wrapper":""}>
          
          <Routes>

            <Route path="/" element={<Home setUser={setUser} />}></Route>

            <Route path="/sign-in" element={<SignIn setUser={setUser} />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/sign-up" element={<SignUp />}></Route>

            <Route path="/orders" element={<Orders />}></Route>
            <Route path="/orders/:id" element={<OrderStatus />}></Route>

            <Route path="/categories" element={<Categories />}></Route>
            <Route path="/categories/add" element={<AddCategory />}></Route>
            <Route path="/categories/edit/:id" element={<EditCategory />}></Route>

            <Route path="/products" element={<Products />}></Route>
            <Route path="/products/add" element={<AddProducts />}></Route>
            <Route path="/products/edit/:id" element={<EditProduct />}></Route>

            <Route path="/locations" element={<Locations />}></Route>
            <Route path="/locations/add" element={<AddLocation />}></Route>
            <Route path="/locations/edit/:id" element={<EditLocation />}></Route>

            <Route path="/on-boarding" element={<Onboarding />}></Route>

            <Route path="/shops" element={<Shops />}></Route>
            <Route path="/shops/add" element={<AddShop />}></Route>
            <Route path="/shops/edit/:id" element={<EditShop />}></Route>

            <Route path="/customers" element={<Customers />}></Route>
            <Route path="/customers/add" element={<AddCustomer />}></Route>
            <Route path="/customers/edit/:userID" element={<EditCustomer />}></Route>

            <Route path="/users" element={<Users />}></Route>
            <Route path="/users/add" element={<AddUser />}></Route>
            <Route path="/users/edit/:id" element={<EditUser />}></Route>

            <Route path="/users/roles" element={<Roles />}></Route>
            <Route path="/users/roles/add" element={<AddRole />}></Route>
            <Route path="/users/roles/edit/:id" element={<EditRole />}></Route>

            <Route path="/seller" element={<BecomeSeller />}></Route>
            
          </Routes>
        </div>
        </UserContext.Provider>
      
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { Link, useNavigate } from "react-router-dom";
function Navbar({Toggle,setUser}) {
  const navigate = useNavigate();
  return (
    <>
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <Link to="/" className="nav-link">Home</Link>
      </li>
    </ul>

    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
          <i className="fas fa-expand-arrows-alt"></i>
        </a>
      </li>

      <li className="nav-item dropdown">
        <a className="nav-link" data-toggle="dropdown" href="#">
          <i className="far fa-user"></i>
          
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <Link to="/profile" className="dropdown-item">
                <h3 className="dropdown-item-title">
                  Admin Admin
                </h3>
          </Link>
          <div className="dropdown-divider"></div>
          <a href="#" className="dropdown-item">
                <img 
                  src="/assets/dist/img/user2-160x160.jpg"
                  style={{width:"100%",
                        height: 120,
                        objectFit: "cover",}}/>
          </a>
          <div className="dropdown-divider"></div>
          <form className="dropdown-item">
                <button 
                onClick={()=>{
                  localStorage.clear();
                  navigate("/sign-in");
                  setUser({loggedIn:false,data:{}});
                }}
                className="btn btn-danger btn-block">
                  Sign out
                </button>
          </form>
        </div>
      </li>
    </ul>
  </nav>
    {/*
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand d-none d-md-block" href="#">
          Dashboard
        </a>
        <a className="navbar-brand d-block d-md-none" onClick={Toggle}>
         <i className="bi bi-justify"></i>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link text-white rounded border" aria-current="page" href="#">
                <i className=" bi bi-search"></i> Search
              </a>
            </li>
            <li className="nav-item mx-2 rounded border">
              <a className="nav-link text-white" aria-current="page" href="#">
                Account
              </a>
            </li>
            <li className="nav-item rounded border">
              <a className="nav-link text-white" aria-current="page" href="#">
                LogOut
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    */}
    </>
  );
}

export default Navbar;

import React,{useEffect} from "react";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import { useNavigate } from "react-router-dom";
import {FaBook, FaBus, FaDollarSign, FaLongArrowAltUp} from "react-icons/fa";


function Home({setUser}) {
  const navigate = useNavigate();
   useEffect(()=>{
    //check user existence
    const userInfo = localStorage.getItem("tms_admin");
    if(userInfo == null){
      navigate("/sign-in");
    } 

    setUser({loggedIn:true,data:JSON.parse(userInfo)})
  },[])
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3   p-3 bg-light ">
            <div className="d-flex justify-content-around py-4 px-2 bg-white align-items-center border border-secondary shadow-sm">
              <FaBook size={30} color="var(--app-color)" />
              <div>
                <span>Bookings</span>
                <h2>234</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3   p-3 bg-light ">
            <div className="d-flex justify-content-around py-4 px-2 bg-white align-items-center  border border-secondary shadow-sm ">
              <FaDollarSign size={30} color="var(--app-color)"/>
              <div>
                <span>Revenue</span>
                <h2>234</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3   p-3 bg-light ">
            <div className="d-flex justify-content-around py-4 px-2 bg-white align-items-center border border-secondary shadow-sm">
              <FaBus size={30} color="var(--app-color)"/>
              <div>
                <span>Buses</span>
                <h2>234</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3  p-3 bg-light ">
            <div className="d-flex justify-content-around py-4 px-2 bg-white align-items-center border border-secondary shadow-sm ">
              <FaLongArrowAltUp size={30} color="var(--app-color)" />
              <div>
                <span>Increase</span>
                <h2>20%</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 p-3">
            <LineChart/>
          </div>
          <div className="col-12 col-md-4 p-3">
            <PieChart/>
          </div>
        </div>
  </div>
    </div>
  );
}

export default Home;

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Product } from '../../Products'

function Shops() {
  return (
    <>
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Shops</h1>
          </div>
        </div>
      </div>
    </div>

    <Shop/>
    <Product/>
    
    
    <section className="content">
        <div className="container-fluid">
            <Link to="/shops/add">
                <button className="btn btn-success btn-md" style={{marginBottom:10}} >
                  Add shop
                  </button>
            </Link>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Manage your shops.</h3>

                            <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: 150,}}>
                                <input type="text" name="table_search" className="form-control float-right" placeholder="Search"/>

                                <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>name</th>
                                    <th>Location</th>
                                    <th>No. of products</th>
                                    <th>isOpen</th>
                                    <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><img src="" alt="shop image" /></td>
                                        <td>Shop 1</td>
                                        <td>1200</td>
                                        <td>5 </td>
                                        <td><input type="checkbox" checked readOnly /></td>
                                        <td>
                                            <a to="#">
                                            <button className="btn btn-primary btn-sm" style={{
                                                marginLeft:10
                                            }} 
                                            data-toggle="modal" data-target="#modal-view"
                                            >View</button>
                                            </a>
                                            <Link to={`/shops/edit/${1}`}>
                                            <button className="btn btn-secondary btn-sm" style={{
                                                marginLeft:10
                                            }} >Edit</button>
                                            </Link>
                                            <a href="#">
                                            <button className="btn btn-danger btn-sm" style={{
                                                marginLeft:10
                                            }} >Delete</button>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>
  )
}

const Shop = ({shop})=>{
    const navigate = useNavigate();
    return(
        <div class="modal fade" id="modal-view">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Shop name</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <tbody>
                                    <tr>
                                        <td><img src="" alt="shop image" /></td>
                                    </tr>
                                    <tr>
                                        <td>name</td>
                                        <td>shop name</td>
                                    </tr>
                                    <tr>
                                        <td>Location</td>
                                        <td>shop location</td>
                                    </tr>
                                    <tr>
                                        <td>Total products</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="#">
                                            <button className="btn btn-danger btn-sm" style={{
                                                marginLeft:10
                                            }} >Delete</button>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal-header">
                        <h4 class="modal-title">Shop's products</h4>
                    </div>
                    <div class="modal-body">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>name</th>
                                    <th>price(ksh.)</th>
                                    <th>Shop</th>
                                    <th>isAvailable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><img src="" alt="product image" /></td>
                                        <td>product name</td>
                                        <td>1200</td>
                                        <td>Shop </td>
                                        <td><input type="checkbox" checked readOnly /></td>
                                        <td>
                                            <a to="#">
                                            <button 
                                            className="btn btn-primary btn-sm" 
                                            style={{
                                                marginLeft:10
                                            }} 
                                            data-toggle="modal" data-target="#modal-view-product"
                                            >View</button>
                                            </a>
                                            <a href='#'>
                                            <button
                                            onClick={()=>{
                                                navigate(`/products/edit/${1}`);
                                            }}
                                            data-dismiss="modal"
                                            className="btn btn-secondary btn-sm" style={{
                                                marginLeft:10
                                            }} >Edit</button>
                                            </a>
                                            <a href="#">
                                            <button className="btn btn-danger btn-sm" style={{
                                                marginLeft:10
                                            }} >Delete</button>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Shops
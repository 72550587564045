import React from 'react'

function AddRole() {
  return (
     <>
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Add role</h1>
          </div>
        </div>
      </div>
    </div>

    
    <section className="content">
        
        <div className="container-fluid">
            <div className="form-group">
                <label htmlFor="name">Role name</label>
                <input required type="text" className="form-control" name="roleName"
                                    placeholder="Role name ..."/>
            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Permissions.</h3>

                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: 150}}>
                                                    <input type="text" name="table_search"
                                                        className="form-control float-right" 
                                                        placeholder="Search"/>

                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="card-body table-responsive p-0">

                                            <table className="table table-hover text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Area</th>
                                                        <th>view</th>
                                                        <th>Create</th>
                                                        <th>edit</th>
                                                        <th>delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> Home data </td>
                                                        <td> <input type="checkbox" name="viewHomeData" /> </td>
                                                        <td> </td>
                                                        <td> <input type="checkbox" name="editHomeData" /> </td>
                                                        <td> <input type="checkbox" name="deleteHomeData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Companies </td>
                                                        <td> <input type="checkbox" name="viewCompanyData" /> </td>
                                                        <td> <input type="checkbox" name="addCompanyData" /></td>
                                                        <td> <input type="checkbox" name="editCompanyData" /> </td>
                                                        <td> <input type="checkbox" name="deleteCompanyData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Categories </td>
                                                        <td> <input type="checkbox" name="viewCategoryData" /> </td>
                                                        <td> <input type="checkbox" name="addCategoryData" /></td>
                                                        <td> <input type="checkbox" name="editCategoryData" /> </td>
                                                        <td> <input type="checkbox" name="deleteCategoryData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Customers </td>
                                                        <td> <input type="checkbox" name="viewCustomerData" /> </td>
                                                        <td> <input type="checkbox" name="addCustomerData" /></td>
                                                        <td> <input type="checkbox" name="editCustomerData" /> </td>
                                                        <td> <input type="checkbox" name="deleteCustomerData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Admins </td>
                                                        <td> <input type="checkbox" name="viewAdminData" /> </td>
                                                        <td> <input type="checkbox" name="addAdminData" /></td>
                                                        <td> <input type="checkbox" name="editAdminData" /> </td>
                                                        <td> <input type="checkbox" name="deleteAdminData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Products </td>
                                                        <td> <input type="checkbox" name="viewProductData" /> </td>
                                                        <td> <input type="checkbox" name="addProductData" /></td>
                                                        <td> <input type="checkbox" name="editProductData" /> </td>
                                                        <td> <input type="checkbox" name="deleteProductData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Orders </td>
                                                        <td> <input type="checkbox" name="viewOrderData" /> </td>
                                                        <td> <input type="checkbox" name="addOrderData" /></td>
                                                        <td> <input type="checkbox" name="editOrderData" /> </td>
                                                        <td> <input type="checkbox" name="deleteOrderData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Roles </td>
                                                        <td> <input type="checkbox" name="viewRoleData" /> </td>
                                                        <td> <input type="checkbox" name="addRoleData" /></td>
                                                        <td> <input type="checkbox" name="editRoleData" /> </td>
                                                        <td> <input type="checkbox" name="deleteRoleData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Reports </td>
                                                        <td> <input type="checkbox" name="viewReportData" /> </td>
                                                        <td> </td>
                                                        <td> <input type="checkbox" name="editReportData" /> </td>
                                                        <td> <input type="checkbox" name="deleteReportData" /> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Profile </td>
                                                        <td> <input type="checkbox" name="viewProfileData" /> </td>
                                                        <td></td>
                                                        <td> <input type="checkbox" name="editProfileData" /> </td>
                                                        <td> <input type="checkbox" name="deleteProfileData" /> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button name="add-role" className="btn btn-primary">Save</button>
                        </div>
                        
                    </section>
    </>
  )
}

export default AddRole;